import dayjs from 'dayjs';
import i18n from '../localization'
import { getEquipmentTypeTranslationKey, getFuelTypeTranslationKey, getPropulsionTranslationKey, unitToTranslationKey } from './convertTranslationKeys';

const propulsions = ['combustionengine', 'electricmotor', 'hybrid'];
const fuelTypes = ['diesel', 'ethanol', 'gas', 'electric'];
const equipmentTypes = ['truck', 'bus', 'engine', 'other'];
const unitsToBeRoundedToInteger = ['km', 'kilometers', 'miles', 'liters', 'litres', 'litre', 'liter', 'gallons', 'kg', 'kilograms', 'nm3'];

const getTranslatedInfoMetricValue = (infoMetricValue: string) => {
  if (propulsions.includes(infoMetricValue?.toLowerCase())) {
    return i18n.t(getPropulsionTranslationKey(infoMetricValue));
  }
  if (fuelTypes.includes(infoMetricValue?.toLowerCase())) {
    return i18n.t(getFuelTypeTranslationKey(infoMetricValue));
  }
  if (equipmentTypes.includes(infoMetricValue?.toLowerCase())) {
    return i18n.t(getEquipmentTypeTranslationKey(infoMetricValue));
  }

  return infoMetricValue;
}

export const converttoTime = (numberOfSeconds: any) => {
  if (Number.isNaN(numberOfSeconds) || numberOfSeconds === 0) {
    return '00:00'
  }
  const d = Number(numberOfSeconds);
  const h = Math.floor(d / 3600);
  const m = Math.floor(d % 3600 / 60);

  const hDisplay = h > 0 ? h : '00'
  const mDisplay = m > 0 ? (m < 10 ? '0' + m : m) : '00'
  return `${hDisplay}:${mDisplay}`
}

export const stripBraces = (stringToModify: string = '') => {
  return stringToModify?.replace('(', '')?.replace(')', '')
}

export const displayFormattedValue = (metric: Metric) => {
  return metric?.value === undefined || metric?.value === null || metric?.value === ""
    ? '-'
    : Number.isNaN(Number(metric.value))
      ? (propulsions.includes(metric.value.toLowerCase())
        ? i18n.t(getPropulsionTranslationKey(metric.value))
        : (fuelTypes.includes(metric.value.toLowerCase())
          ? i18n.t(getFuelTypeTranslationKey(metric.value))
          : (equipmentTypes.includes(metric.value.toLowerCase())
            ? i18n.t(getEquipmentTypeTranslationKey(metric.value))
            : metric.value)))
      : Number.isInteger(Number(metric.value))
        ? (metric.unit === 'seconds')
          ? converttoTime(metric.value)
          : Number(metric.value)
        : (metric.unit === 'seconds')
          ? converttoTime(metric.value)
          : (unitsToBeRoundedToInteger.includes((metric.unit || '').toLowerCase()) ||
            metric.name.toLowerCase().includes('driver_evaluation.score') ||
            metric.name.toLowerCase().includes('speed.average'))
              ? Math.round(Number(metric.value))
              : Number(metric.value.toFixed(2));
}

export const stripNonAlphaCharacters = (stringToModify: string = '') => {
  return stringToModify.replace('(', '')
    .replace(')', '')
    .replace(',', '')
    .replace('/', '')
    .replace('0', '')
    .replace('1', '')
    .replace('2', '')
    .replace('3', '')
    .replace('{', '')
    .replace('}', '')
    .replace('{}', '');
}

export const getMatchingPropForVehicleName = (vehicleIdentifier: string = ''): 'registrationNumber' | 'chassisNumber' | 'alias' => {
  switch (vehicleIdentifier) {
    case 'Registration number': return 'registrationNumber'
    case 'Chassi number': return 'chassisNumber'
    case 'Vehicle alias': return 'alias'
    default: return 'chassisNumber'
  }
}

export const getAvailableIdentifier = (vehicleIdentifier: string, equipmentInfo: { alias: string, chassisNumber: string, registrationNumber: string}) => {
  switch (vehicleIdentifier) {
    case 'registrationNumber': return equipmentInfo[vehicleIdentifier] || equipmentInfo?.alias || equipmentInfo?.chassisNumber || '-';
    case 'chassisNumber': return equipmentInfo[vehicleIdentifier] || equipmentInfo?.alias || equipmentInfo?.registrationNumber || '-';
    default: return equipmentInfo?.alias || equipmentInfo?.registrationNumber || equipmentInfo?.chassisNumber || '-';
  }
}

export const transformToAcceptedGroupPayload = (selectedGroupOption: string = 'all') => {
  switch (selectedGroupOption) {
    case 'groupFilterAll': return undefined;
    case 'all': return undefined;
    case 'groupFilterNoAffiliation': return 'none';
    default: return selectedGroupOption;
  }
}

const compareValues = (a: any, b: any, sortOrder: 'asc' | 'desc') => {
  if (a === null && b === null) return 0;
  if (a === null) return sortOrder === 'asc' ? -1 : 1;
  if (b === null) return sortOrder === 'asc' ? 1 : -1;
  if (a === b) return 0;
  if (typeof a === 'number' && typeof b === 'number') {
    return a > b ? (sortOrder === 'asc' ? 1 : -1) : (sortOrder === 'asc' ? -1 : 1);
  }
  return a.toString().localeCompare(b.toString(), 'en') * (sortOrder === 'asc' ? 1 : -1);
};

const getTableWidgetItemValue = (element: TableWidgetEquipment, sortField: string) => {
  const identifierPossibilities = [
    'alias',
    'registrationNumber',
    'chassisNumber',
  ];
  let itemToCompare;

  if (identifierPossibilities.includes(sortField)) {
    itemToCompare = getAvailableIdentifier(sortField, element.equipmentInfo);
  } else {
    const indexOfParameter = element.parameters.findIndex(metric => metric.name?.toLowerCase()?.trim() === sortField.toLowerCase().trim());
    itemToCompare = sortField.toLowerCase().includes('info') ?
                      getTranslatedInfoMetricValue(element.parameters[indexOfParameter].value) :
                      itemToCompare = element.parameters[indexOfParameter].value;
  }

  return itemToCompare;
}

export const sortRows = (
  sortField: string = '',
  sortOrder: 'asc' | 'desc' = 'asc',
  tableData: Array<TableWidgetEquipment> = []
) => {
  if (sortField.length === 0 || tableData?.length <= 1) {
    return tableData;
  }

  return [...tableData].sort((a: TableWidgetEquipment, b: TableWidgetEquipment) => {
    const itemA = getTableWidgetItemValue(a, sortField);
    const itemB = getTableWidgetItemValue(b, sortField);

    return compareValues(itemA, itemB, sortOrder);
  });
};

const getFuelItemValue = (item: FuelReportItem, sortField: string, vehicleIdentifier: string) => {
  switch (sortField.toLowerCase()) {
    case 'equipment':
      return getAvailableIdentifier(vehicleIdentifier, {
        alias: item.equipment.alias,
        chassisNumber: item.equipment.chassisNumber,
        registrationNumber: item.equipment.registrationNumber,
      });
    case 'time':
      return item.eventTime ? dayjs(item.eventTime).unix() : null;
    case 'odometer':
      return item.odometerInMeters;
    case 'event':
      return item.refuelType;
    case 'driver':
      return item.driver?.name || i18n.t('IngetFörar_Id');
    case 'increase':
      return item.increaseInPercentage;
    default:
      return item.address;
  }
};

export const sortFuelTableData = (
  sortField: string = '',
  sortOrder: 'asc' | 'desc' = 'asc',
  vehicleIdentifier: string,
  fuelTableData: FuelReportItem[] = []
) => {
  if (sortField.length === 0 || fuelTableData.length <= 1) {
    return fuelTableData;
  }

  return [...fuelTableData].sort((a, b) => {
    const itemA = getFuelItemValue(a, sortField, vehicleIdentifier);
    const itemB = getFuelItemValue(b, sortField, vehicleIdentifier);
    return compareValues(itemA, itemB, sortOrder);
  });
};

export const createDeepCopy = (obj: any): any => {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  if (obj instanceof Date) {
    let copy = new Date();
    copy.setTime(obj.getTime());
    return copy;
  }

  if (obj instanceof Array) {
    let copy = [];
    for (let i = 0; i < obj.length; i++) {
      copy[i] = createDeepCopy(obj[i]);
    }
    return copy;
  }

  if (obj instanceof Object) {
    let copy: any = {};
    for (let attr in obj) {
      if (obj.hasOwnProperty(attr)) {
        copy[attr] = createDeepCopy(obj[attr]);
      }
    }
    return copy;
  }
};

export const generateUnitSubheaderText = (unit: string) => {
  let propulsionType: string = '';

  if (unit?.toLowerCase()?.includes('kg') || unit?.toLowerCase()?.includes('nm3')) {
    propulsionType = i18n.t('Gas');
  }

  if (unit?.toLowerCase()?.includes('kwh')) {
    propulsionType = i18n.t('Electricitet');
  }

  if (propulsionType) {
    return `${propulsionType.toLowerCase()} (${stripBraces(i18n.t(unitToTranslationKey(unit)))})`;
  }

  return stripBraces(i18n.t(unitToTranslationKey(unit))) || '-';
};

export const generateInfoMetricSubheaderText = (metricName: string = '') => {
  switch (metricName) {
    case 'info.equipment.brand': return i18n.t('Tillverkare').toLowerCase();
    case 'info.equipment.equipment_type': return i18n.t('Utrustningstyp').toLowerCase();
    case 'info.equipment.model': return i18n.t('Modell').toLowerCase();
    case 'info.equipment.engine_designation': return i18n.t('TH_Motor').toLowerCase();
    case 'info.equipment.fuel_type': return i18n.t('Bränsletyp').toLowerCase();
    case 'info.equipment.propulsion_system': return i18n.t('DE_PropulsionType').toLowerCase();
    default: return i18n.t(metricName).toLowerCase();
  }
}

export const generateSupportScoreMetricSubheaderText = (metricName: string = '') => {
  switch (metricName) {
    case 'custom.calculated.driver_evaluation.score.average': return i18n.t('GenomsnittspoängI_').toLowerCase();
    case 'custom.calculated.driver_evaluation.score.anticipation': return i18n.t('Förutseende___').toLowerCase();
    case 'custom.calculated.driver_evaluation.score.hill': return i18n.t('Backkörning___').toLowerCase();
    case 'custom.calculated.driver_evaluation.score.gear_selection': return `${i18n.t('Växelval')} (${stripNonAlphaCharacters(i18n.t('_0_Procent'))})`.toLowerCase();
    case 'custom.calculated.driver_evaluation.score.hybrid_brake': return `${i18n.t('VP_RegenerativeBrake')} (${stripNonAlphaCharacters(i18n.t('_0_Procent'))})`.toLowerCase();
    case 'custom.calculated.driver_evaluation.score.wear': return `${i18n.t('Bromsanvändning')} (${stripNonAlphaCharacters(i18n.t('_0_Procent'))})`.toLowerCase();
    default: return i18n.t(metricName).toLowerCase();
  }
}

export const generateSubheaderLabel = (metric: Metric = { name: '', unit: '', value: null }) => {
  if (metric) {
    // the metric is part of the equipment_information group
    if (metric.name && metric.name.includes('info.equipment')) {
      return generateInfoMetricSubheaderText(metric.name);
    }
    // the metric is part of the support_score group
    if (metric.name && metric.name.includes('driver_evaluation.score')) {
      return generateSupportScoreMetricSubheaderText(metric.name);
    }
    // the metric is neither equipment_info nor support_score metric
    if (metric.unit) {
      return generateUnitSubheaderText(metric.unit);
    }

    // the unit is undefined
    return '-';
  }

  return '-';
}

export const adjustOdometerToProfileSetting = (odometerInMeters: number, propulsionConsumptionUnit: string = 'kmperprop') => {
  if (odometerInMeters === null || odometerInMeters === undefined) {
    return '-';
  }

  if (propulsionConsumptionUnit.includes('miles')) {
    // tranform from meters to miles
    return Math.round(odometerInMeters * 0.000621371);
  }

  // transform from meters to km
  return Math.round(odometerInMeters / 1000);
}

export const displayAvailableDriverIdentifier = (driverDetails: any) => {
  if (driverDetails.staffDriver) {
    return driverDetails.staffDriver.firstName + ' ' + driverDetails.staffDriver.lastName;
  }
  if (driverDetails.identification) return driverDetails.identification;
  return 'OkändaFörar_Id';
}

export const sortDriverData = (driverData: any, sortParameter: string, sortOrder: 'asc' | 'desc') => {
  if (!sortParameter.length) return driverData;

  const identifierPossibilities = [
    'alias',
    'registrationnumber',
    'chassisnumber',
  ];

  const indexOfParam = driverData[0].parameters.findIndex((element: { name: string; }) => element.name.toLowerCase() === sortParameter.toLowerCase());

  const sorted = [...driverData].sort((a: any, b: any) => {
    let itemA, itemB;
    if (identifierPossibilities.includes(sortParameter.toLowerCase())) {
      itemA = i18n.t(displayAvailableDriverIdentifier(a));
      itemB = i18n.t(displayAvailableDriverIdentifier(b));
    } else {
      if (sortParameter.toLowerCase().includes('info')) {
        itemA = getTranslatedInfoMetricValue(a.parameters[indexOfParam].value);
        itemB = getTranslatedInfoMetricValue(b.parameters[indexOfParam].value);
      } else {
        itemA = a.parameters[indexOfParam].value;
        itemB = b.parameters[indexOfParam].value;
      }
    }

    if (itemA === null && itemB === null) return 0;
    if (itemA === null) {
      return sortOrder === 'asc' ? -1 : 1;
    }
    if (itemB === null) {
      return sortOrder === 'asc' ? 1 : -1;
    }

    if (itemA === itemB) return 0;

    if (typeof itemA === 'number' && typeof itemB === 'number') {
      return itemA > itemB
        ? sortOrder === 'asc'
          ? 1
          : -1
        : sortOrder === 'asc'
        ? -1
        : 1;
    } else {
      return (
        itemA.toString().localeCompare(itemB.toString(), 'en') *
        (sortOrder === 'asc' ? 1 : -1)
      );
    }
  });

  return sorted;
};

const anyEquipmentInfoParamMatchesSearch = (parameters: Array<Metric> = [], searchedText: string = '') => {
  const eqInfoParams = parameters.filter(metric => metric.name?.toLowerCase().includes('info.equipment'));
  if (eqInfoParams.length > 0) {
    // see if any equipment info metric translated value contains the searched text
    const anyInfoMetricIndex = eqInfoParams.findIndex(metric => displayFormattedValue(metric).toLowerCase().includes(searchedText.toLowerCase()));

    if (anyInfoMetricIndex > -1) return true;
  }

  return false;
}

export const filterTableRowsOnSearch = (
  searchedText: string = '',
  currentTableData: TableDataStructure,
  staffVehicleIdentifier: string,
  sortDetails: { sortField: string; sortOrder: string; }
) => {
  const tableDataDeepCopy = createDeepCopy(currentTableData);

  if (searchedText.length > 0) {
    const filteredTableDataContent = tableDataDeepCopy?.equipments?.filter((singleVehicle: TableWidgetEquipment) => 
      getAvailableIdentifier(getMatchingPropForVehicleName(staffVehicleIdentifier), singleVehicle.equipmentInfo)
        ?.toLowerCase()?.includes(searchedText.toLowerCase()) 
      || anyEquipmentInfoParamMatchesSearch(singleVehicle.parameters, searchedText)
    );
    
    tableDataDeepCopy.equipments = filteredTableDataContent;
  }

  if (sortDetails.sortField.length > 0) {
    const normalizedSortOrder: 'asc' | 'desc' = (sortDetails.sortOrder.toLowerCase() === 'desc') ? 'desc' : 'asc';
    const sortedEquipments = sortRows(
      sortDetails.sortField,
      normalizedSortOrder,
      tableDataDeepCopy.equipments
    );

    tableDataDeepCopy.equipments = sortedEquipments;
  }
  
  return tableDataDeepCopy;
}


export const getDashboardGroupParameters = (dataRow: any[] = []) => {
  const metrics: any[] = [];

  if (dataRow?.length > 0) {
    dataRow.forEach(column => {
      column.subColumns?.forEach((subcolumn: string) => metrics.push(subcolumn))
    });
  }

  return metrics;
}

export const getAssociatedNameForGroup = (groupRef: string = '', customerGroups: Group[] = []) => {
  const groupName = customerGroups.find(group => group.externalEquipmentGroupReference === groupRef);
  if (groupName) return groupName.name;

  return groupRef;
}