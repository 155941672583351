export const unitToTranslationKey = (unit: string = '') => {
  switch (unit.toLowerCase()) {
    case 'minutes': return 'H_M';
    case 'seconds': return 'H_M';
    case 'litres': return 'UNIT_litres';
    case 'liters': return 'UNIT_litres';
    case 'litre': return 'UNIT_litres';
    case 'liter': return 'UNIT_litres';
    case 'kg': return 'Kg';
    case 'kilograms': return 'Kg';
    case 'nm3': return 'Nm3';
    case 'gallons': return 'UNIT_gal';
    case 'tonne': return 'UNIT_tonne';
    case 'tonnes': return 'UNIT_tonne';
    case 'tonne-kilometers': return 'Tonkm';
    case 'tonne-km': return 'Tonkm';
    case 'tonne-km/liters': return 'Tonkm_L';
    case 'tonne-miles': return 'VPu_tonnemiles';
    case 'tonne-kilometers/liters': return 'Tonkm_L';
    case 'tonne-miles/gallons': return 'Tonmiles_Gal';
    case 'tonne-km/kg': return 'Tonkm_Kg';
    case 'tonne-kilometers/kg': return 'Tonkm_Kg';
    case 'tonne-kilometers/kilograms': return 'Tonkm_Kg';
    case 'tonne-km/nm3': return 'VPu_tonnekmNm3';
    case 'tonne-miles/kilograms': return 'Tonmiles_Kg';
    case 'tonne-miles/kg': return 'Tonmiles_Kg';
    case 'tonne-miles/nm3': return 'VPu_tonnesmilesNm3';
    case 'tonne-km/kwh': return 'VPu_tonneskmkWh';
    case 'tonne-miles/kwh': return 'VPu_tonnesmileskWh';
    case '%': return '%';
    case 'percent': return '%';
    case 'count': return '#';
    case 'km': return 'UNIT_km';
    case 'kilometers': return 'UNIT_km';
    case 'miles': return 'UNIT_mi';
    case 'kwh/100km': return 'KWh_100Km';
    case 'per100km': return '__100Km';
    case 'count/100km': return '__100Km';
    case '#/100km': return '__100Km';
    case 'per100miles': return '__100Miles';
    case '#/100miles': return '__100Miles';
    case 'count/100miles': return '__100Miles';
    case 'l/100km': return 'L_100Km';
    case 'litre/100km': return 'L_100Km';
    case 'litres/100km': return 'L_100Km';
    case 'liters/100km': return 'L_100Km';
    case 'km/l': return 'Km_L';
    case 'km/liters': return 'Km_L';
    case 'kilometers/liters': return 'Km_L';
    case 'mpg': return 'Mpg';
    case 'miles/gallons': return 'Mpg';
    case 'kg/100km': return 'Kg_100Km';
    case 'km/kg': return 'Km_Kg';
    case 'miles/kg': return 'Miles_Kg';
    case 'nm3/100km': return 'VPu_Nm3100km';
    case 'km/nm3': return 'VPu_kmNm3';
    case 'miles/nm3': return 'VPu_milesNm3';
    case 'litres/h': return 'Liter_H';
    case 'litre/h': return 'Liter_H';
    case 'liters/h': return 'Liter_H';
    case 'liter/h': return 'Liter_H';
    case 'gallons/h': return 'VPu_gallonsh';
    case 'l/h': return 'L_H';
    case 'km/h': return 'Km_H';
    case 'miles/h': return 'Mph';
    case 'mph': return 'Mph';
    case 'kg/h': return 'VPu_kgh';
    case 'kwh/h': return 'VPu_kWhh';
    case 'nm3/h': return 'VPu_Nm3h';
    case 'kwh': return 'KWh';
    case 'km/kwh': return 'Km_KWh';
    case 'miles/kwh': return 'VPu_mileskWh';
    case 'kWh/100 miles': return 'KWh_100Miles';
    case '%ofdistance': return '_AvSträcka';
    case 'distancepercent': return '_AvSträcka';
    case '%ofenginetime': return '_AvMotordrifttid';
    case 'timepercent': return '_AvMotordrifttid';
    default: return unit;
  }
}

export const headerTitleToTranslationKey = (groupTitle: string = '') => {
  switch (groupTitle.toLowerCase()) {
    case 'vehicle_name': return 'Utrustning';
    case 'average_speed': return 'Medelhastighet';
    case 'average_speed_drive': return 'TH_MedelhastighetKörning__0___1__';
    case 'average_speed_drive_idle': return 'TH_MedelhastighetKörningOchTomgångskörning__0___1__';
    case 'average_weight': return 'Medelvikt';
    case 'cruise_control': return 'SträckaMedFarthållare';
    case 'fuel_type': return 'TH_Bränsletyp';
    case 'odometer': return 'TH_TotalOdometer';
    case 'driven_distance': return 'KördSträcka';
    case 'engineDesignation': return 'TH_Motor';
    case 'equipmentType': return 'TH_Vehicle';
    case 'brake_applications': return 'Inbromsningar';
    case 'harsh_accelerations': return 'KraftigaAccelerationer';
    case 'harsh_brake_applications': return 'KraftigaInbromsningar';
    case 'powertrain_coasting': return 'TH_Coasting';
    case 'powertrain_coasting_manual': return 'VP_PowertrainCoasting';
    case 'freewheel_coasting': return 'Frirullning';
    case 'fuel_consumption': return 'TH_FuelConsumption';
    case 'fuel_consumption_driving_and_idling': return 'TH_Bränsleförbrukning_KörningOchTomgångskörning__0___1__';
    case 'fuel_consumption_driving': return 'TH_BränsleförbrukningKörning__0__';
    case 'fuel_consumption_idling': return 'TH_Bränsleförbrukning_Tomgångskörning__0___1__';
    case 'fuel_consumption_pto': return 'TH_BränsleförbrukningKraftuttagskörning__0__';
    case 'idling_time': return 'TH_Idling';
    case 'speeding': return 'TH_OverSpeeding';
    case 'overrevving': return 'Övervarvning';
    case 'transport_work': return 'Transportarbete';
    case 'runtime': return 'Motordrifttid';
    case 'pause_mode': return 'Pausläge';
    case 'power_take_off_speed': return 'Kraftuttagskörning';
    case 'trailer_distance': return 'SträckaMedSläpfordon';
    case 'trailer_weight': return 'VP_TrailerWeight';
    case 'performance_mode_economy': return 'Prestandaläge_Ekonomi';
    case 'performance_mode_offroad': return 'Prestandaläge_Terräng';
    case 'performance_mode_power': return 'Prestandaläge_Power';
    case 'performance_mode_standard': return 'Prestandaläge_Standard';
    case 'pto_time': return 'VP_PowerTakeoff';
    case 'cO2': return 'TH_CO2Total';
    case 'co': return 'CO';
    case 'nox': return 'NOx';
    case 'pm': return 'PM';
    case 'hc': return 'HC';
    case 'support_score': return 'TH_DSScore';
    case 'driving_time': return 'VP_Driving';
    case 'engine_running_time': return 'Motordrifttid';
    case 'total_engine_time': return 'VP_SammanlagdMotordrifttid';
    case 'total_reductant_used': return 'AdBlue';
    case 'equipment_information': return 'Utrustningsinformation';
    default: return groupTitle;
  }
}

export const vehicleIdentifierToTranslationKey = (vehicleIdentifier: string = '') => {
  switch (vehicleIdentifier) {
    case 'registrationNumber': return 'Registreringsnummer';
    case 'chassisNumber': return 'Chassinr';
    case 'alias': return 'AliasEquipment';
    default: return 'Utrustning';
  }
}

export const getNameTranslationKey = (name: string = '') => {
  switch (name.toLowerCase()) {
    case 'economy': return 'TH_Prestandaläge_Ekonomi__0__';
    case 'driving': return 'VP_Driving';
    case 'consumption': return 'VP_Consumption';
    case 'hybrid_vehicles': return 'Hybridfordon';
    case 'load': return 'VP_Load';
    case 'performance mode': return 'VP_PerformanceMode';
    case 'support_score': return 'TH_DSScore';
    case 'pto': return 'VP_PowerTakeoff';
    case 'utilization': return 'VP_Utilization';
    case 'equipment_information': return 'VP_EquipmentInformation';
    default: return name;
  }
}

export const getPropulsionTranslationKey = (propulsionSystem: string = '') => {
  switch (propulsionSystem.toLowerCase()) {
    case 'combustionengine': return 'VP_CombustionEngine';
    case 'electricmotor': return 'VP_ElectricMachine';
    case 'hybrid': return 'VP_Hybrid';
    default: return propulsionSystem;
  }
}

export const getFuelTypeTranslationKey = (fuelType: string = '') => {
  switch (fuelType.toLowerCase()) {
    case 'hybrid': return 'hybrid';
    case 'electric': return 'electric';
    case 'diesel': return 'Diesel';
    default: return fuelType;
  }
}

export const getEquipmentTypeTranslationKey = (equipmentType: string = '') => {
  switch (equipmentType.toLowerCase()) {
    case 'truck': return 'Lastbil';
    case 'bus': return 'Buss';
    case 'engine': return 'Motor';
    default: return equipmentType;
  }
}

export const getGroupKey = (groupFilterOption: string = '') => {
  switch (groupFilterOption) {
    case 'groupFilterAll': return 'AllaGrupper';
    case 'groupFilterNoAffiliation': return 'IngenGrupptillhörighet';
    default: return groupFilterOption;
  }
}
