import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { useAppSelector } from '../../state/store';
import {
  getOverviewStartDate,
  getOverviewEndDate,
} from '../../state/localSettings/selectors';
import { useIsFeatureEnabled } from '../../utils/hooks';

const SideMenu = ({ language }: any) => {
  const { t } = useTranslation();
  const location = useLocation()
  const isDashboardPageAccessible = useIsFeatureEnabled('VPHEV');

  const overviewStartDate = useAppSelector(getOverviewStartDate);
  const overviewEndDate = useAppSelector(getOverviewEndDate);

  return (
    <sss-navbar-side-menu culture={language}>
      { isDashboardPageAccessible &&
        <sss-navbar-menu-item
          icon='cui-scania-fms-details'
          label={t('Anslagstavla')}
          class={`sss-navbar-menu-nav-item hydrated ${location.pathname === '/dashboard' ? 'active' : ''}`}
          >
          <Link
            className='sss-navbar-menu-nav-link'
            to='/dashboard'>
            {t('Anslagstavla')}
          </Link>
        </sss-navbar-menu-item>
      }
      <sss-navbar-menu-item
        icon='cui-scania-fms-overview'
        label={t('Översikt')}
        class={`sss-navbar-menu-nav-item hydrated ${location.pathname === '/overview' ? 'active' : ''}`}
        >
        <Link
          className='sss-navbar-menu-nav-link'
          to={`/overview?periodStart=${overviewStartDate}&periodEnd=${overviewEndDate}`}>
          {t('Översikt')}
        </Link>
      </sss-navbar-menu-item>
      <sss-navbar-menu-item
        icon='cui-scania-fms-fuelreport'
        label={t('Bränslerapport')}
        class={`sss-navbar-menu-nav-item hydrated ${location.pathname === '/fuel-report' ? 'active' : ''}`}
        >
        <Link
          className='sss-navbar-menu-nav-link'
          to='/fuel-report'>
          {t('Bränslerapport')}
        </Link>
      </sss-navbar-menu-item>
      <sss-navbar-menu-item
        icon='cui-scania-fms-settings'
        label={t('Inställningar')}
        class={`sss-navbar-menu-nav-item hydrated ${location.pathname === '/settings' ? 'active' : ''}`}
        >
        <Link
          className='sss-navbar-menu-nav-link'
          to='/settings'>
          {t('Inställningar')}
        </Link>
      </sss-navbar-menu-item>
    </sss-navbar-side-menu>
  );
};

export default SideMenu;
