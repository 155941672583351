import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TdsIcon, TdsTooltip } from '@scania/tegel-react';
import {
  headerTitleToTranslationKey,
  vehicleIdentifierToTranslationKey,
} from '../../utils/report/convertTranslationKeys';
import {
  generateSubheaderLabel,
  stripNonAlphaCharacters,
} from '../../utils/report/tableUtils';
import styles from '../../styles/DataTableHeader.module.css';
import { groupsExplanationsKeys } from '../../utils/report/paramsGroups';

type DataTableHeaderProps = {
  vehicleIdentifier: string;
  propulsionConsumptionUnit: string;
  tableWidgetSpecs: WidgetSpecification;
  parameterDetails: Array<Metric>;
  sortTableContent: Function;
};

const DataTableHeader = ({
  vehicleIdentifier,
  propulsionConsumptionUnit,
  tableWidgetSpecs,
  parameterDetails,
  sortTableContent,
}: DataTableHeaderProps) => {
  const { t } = useTranslation();
  const [sortField, setSortField] = useState('');
  const [order, setOrder] = useState('asc');
  const [hoveredSubth, setHoveredSubth] = useState('');
  const userUnitsMeasureSystem = propulsionConsumptionUnit.includes('miles')
    ? 'imperial'
    : 'metric';

  const handleSortingChange = (accessor: string) => {
    const sortOrder =
      accessor === sortField && order === 'asc' ? 'desc' : 'asc';
    setSortField(accessor);
    setOrder(sortOrder);
    sortTableContent(accessor, sortOrder);
  };

  const hoverFirstUnitChild = (cellIndex: number) => {
    setHoveredSubth(`tableSubHeader${cellIndex}_1`);
  };

  const unhoverFirstUnitChild = () => {
    setHoveredSubth('');
  };

  return (
    <thead>
      <tr>
        <th
          id='tableHeader_1'
          key='keyTableHeader1'
          className={`${
            styles.firstRowHeaderCell
          } leftAlignedText stickyTopHeaderParam firstColumnZIndex ${
            styles.stickyLeft
          } ${sortField !== vehicleIdentifier && styles.hoverableHeader} ${
            sortField === vehicleIdentifier && styles.sortedGroupHeader
          }`}
          onMouseOver={() => hoverFirstUnitChild(1)}
          onMouseOut={() => unhoverFirstUnitChild()}>
          <div className={styles.thContent}>
            <div>{t('Utrustning')}</div>
          </div>
        </th>
        {tableWidgetSpecs && tableWidgetSpecs.properties?.columns?.map((currentColumnDetails: { columnName: string; subColumns: string[]}, index: number) =>
          (
            <th
              id={`tableHeader${index + 2}`}
              key={`keyTableHeader${index + 2}`}
              colSpan={currentColumnDetails?.subColumns?.length}
              className={`${styles.firstRowHeaderCell} leftAlignedText stickyTopHeaderParam ${styles.normalColWidth} ${
                !(sortField.includes(currentColumnDetails.columnName)) && styles.hoverableHeader} ${
                sortField.includes(currentColumnDetails.columnName) &&
                styles.sortedGroupHeader}`}
              onMouseEnter={() => hoverFirstUnitChild(index + 2)}
              onMouseLeave={() => unhoverFirstUnitChild()}>
              <div className={`${styles.thContent} fullWidth`}>
                <div
                  className={`fullWidth ${styles.overflowTh}`}
                  id={`overflowingTableHeader${index + 2}`}>
                  {stripNonAlphaCharacters(
                    t(headerTitleToTranslationKey(currentColumnDetails.columnName))
                  )}
                </div>
                <TdsTooltip
                  placement='top'
                  selector={`#tableHeader${index + 2}`}
                  className={styles.headerTooltips}
                  text=''>
                  <div>
                    <b>
                      {stripNonAlphaCharacters(
                        t(headerTitleToTranslationKey(currentColumnDetails.columnName))
                      )}
                    </b>
                    <br />
                    {(groupsExplanationsKeys[currentColumnDetails.columnName] &&
                      groupsExplanationsKeys[currentColumnDetails.columnName][userUnitsMeasureSystem]) ?
                        t(groupsExplanationsKeys[currentColumnDetails.columnName][userUnitsMeasureSystem]) : ''}
                  </div>
                </TdsTooltip>
              </div>
            </th>
          )
        )}
      </tr>
      <tr>
        <th
          key='keyTableSubHeader1_1'
          id='tableSubHeader1_1'
          className={`leftAlignedText firstColumnZIndex stickyTopUnit ${
            styles.stickyLeft
          } ${styles.subTh} ${styles.hoverable} ${
            sortField === vehicleIdentifier ? styles.sortedColumn : ''
          } ${
            hoveredSubth === 'tableSubHeader1_1' &&
            sortField !== vehicleIdentifier
              ? styles.hoveredSubth
              : ''
          }`}
          onClick={() => handleSortingChange(vehicleIdentifier)}>
          <div className={styles.thContent}>
            <div className={styles.leftAlignedText}>
              {t(vehicleIdentifierToTranslationKey(vehicleIdentifier))}
              <span className={`${sortField.toLowerCase() !== vehicleIdentifier.toLowerCase() ? styles.beforeIcons : styles.iconsContainer}`}>
                {sortField === vehicleIdentifier ?
                  <TdsIcon name={order === 'desc' ? 'arrow_down' : 'arrow_up'} /> : <TdsIcon name='sorting' /> 
                }
              </span>
            </div>
          </div>
        </th>
        {tableWidgetSpecs.properties?.columns?.map((currentColumnDetails: { columnName: string; subColumns: string[]}, index: number) => (
          currentColumnDetails.subColumns?.map(
            (
              metricName: string,
              unitIndex: number
            ) => {
              const metricDetails = parameterDetails.find(metric => metric.name.toLowerCase() === metricName.toLowerCase());
              return <th
                key={`keyTableSubHeader${index + 2}_${unitIndex + 1}`}
                id={`tableSubHeader${index + 2}_${unitIndex + 1}`}
                className={`rightAlignedText stickyTopUnit ${styles.subTh} ${
                  styles.hoverable
                } ${
                  sortField.toLowerCase() === metricName.toLowerCase() ? styles.sortedColumn : ''
                } ${
                  hoveredSubth === `tableSubHeader${index + 2}_1` &&
                  unitIndex === 0 &&
                  sortField.toLowerCase() !== metricName.toLowerCase()
                    ? styles.hoveredSubth
                    : ''
                }`}
                onClick={() =>
                  handleSortingChange(metricName)
                }>
                <div className={styles.thContent}>
                  <span className={`${sortField.toLowerCase() !== metricName.toLowerCase() ? styles.beforeIcons : styles.iconsContainer}`}>
                    { sortField.toLowerCase() !== metricName.toLowerCase() ?
                      <TdsIcon name='sorting' /> :
                      <TdsIcon name={order === 'desc' ? 'arrow_down' : 'arrow_up'} />
                    }
                  </span>
                  <span>{generateSubheaderLabel(metricDetails)}</span>
                </div>
              </th>
            }
          )
        ))}
      </tr>
    </thead>
  );
};

export default DataTableHeader;
