import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import { TdsMessage, TdsSpinner, TdsTooltip } from '@scania/tegel-react';

import { useGetDriverDetailsQuery } from '../../state/driverDetails/query';
import {
  displayAvailableDriverIdentifier,
  displayFormattedValue,
  sortDriverData
} from '../../utils/report/tableUtils';
import redirectIcon from '../../assets/redirect.svg';

import ExportExcelButton from '../export/ExportExcelButton';

import styles from '../../styles/DataTable.module.css';

type DriverDetailsTableProps = {
  staffDetails: StaffDetails;
  periodStart: string;
  periodEnd: string;
  sortDetails: { sortField: string, sortOrder: string };
  equipmentReference: string;
  tableWidgetSpecs: WidgetSpecification | null;
  parameterDetails: string[];
  vehicleRowIndex: number;
  vehicleData: TableWidgetEquipment | null;
}

const defaultProps = {
  staffDetails: {
    externalStaffReference: '',
    language: 'en-GB',
    vehicleIdentifier: 'Vehicle alias',
    propulsionConsumptionUnit: 'milesPerProp',
    gasConsumptionUnit: 'Kg',
    firstName: '',
    lastName: '',
    customer: {
      externalCustomerReference: '',
      name: '',
      utcDiff: '+02:00',
      timeZone: 'W. Europe Standard Time',
      startOfWeek: 'Monday'
    }
  },
  periodStart: '',
  periodEnd: '',
  sortDetails: { sortField: '', sortOrder: '' },
  equipmentReference: '',
  tableWidgetSpecs: null,
  parameterDetails: [],
  vehicleRowIndex: 0,
  vehicleData: null
};

const isUnknownDriver = (driverDetails: any) => {
  return driverDetails.staffDriver === null && driverDetails.identification === null;
}

const getDriverInitials = (driverDetails: any) => {
  const firstName = driverDetails.staffDriver?.firstName
  const lastName = driverDetails.staffDriver?.lastName

  if(firstName && lastName) {
    return `${firstName[0]}${lastName[0]}`
  }

  return ''
}

const goToDriver = (driverDetails: any) => {
  if(driverDetails.staffDriver?.externalStaffReference) {
    window.open(`${process.env.REACT_APP_DRIVER_EVALUATION_HOST}/results?staff=${driverDetails.staffDriver?.externalStaffReference}`, '_blank');
  } else if (driverDetails.externalDriverReference) {
    window.open(`${process.env.REACT_APP_DRIVER_EVALUATION_HOST}/results?driver=${driverDetails.externalDriverReference}`, '_blank');
  }
}

const DriverDetailsTable = ({
  staffDetails,
  periodStart,
  periodEnd,
  sortDetails,
  equipmentReference,
  tableWidgetSpecs,
  parameterDetails,
  vehicleRowIndex,
  vehicleData
}: DriverDetailsTableProps = defaultProps) => {
  const { t } = useTranslation();

  const {
    data: driverData,
    isLoading: isDriverDataLoading,
    isSuccess: driverDataLoadedSuccessfully,
    isError: isErrorLoadingDriverData,
    isFetching: isDriverDataFetching,
  } = useGetDriverDetailsQuery(
    staffDetails.externalStaffReference.length > 0 && periodStart && periodEnd && equipmentReference
      ? {
          queryStart: periodStart,
          queryStop: periodEnd,
          propulsionConsumptionUnit: staffDetails
            ? staffDetails.propulsionConsumptionUnit
            : null,
          gasConsumptionUnit: staffDetails.externalStaffReference.length > 0 ? staffDetails.gasConsumptionUnit : null,
          externalEquipmentReference: equipmentReference,
          utcDiff: staffDetails && staffDetails.externalStaffReference.length > 0 && staffDetails.customer?.utcDiff ? staffDetails.customer.utcDiff : null,
          parameters: parameterDetails
        }
      : skipToken
  );

  const tableWidth = document.querySelector('.tableContainer')?.getBoundingClientRect().width;
  const sortedDriverData = driverData && driverData?.length 
  ? sortDriverData(
      driverData, 
      sortDetails.sortField, 
      (sortDetails.sortOrder.toLowerCase() === 'desc' ? 'desc' : 'asc')
    ) 
  : [];

  return <>
    {(isDriverDataFetching || isDriverDataLoading) && (
      <tr className={`${styles.tableRow} ${vehicleRowIndex % 2 === 0 ? styles.evenRow : styles.oddRow}`}>
        <td colSpan={parameterDetails.length + 1}>
          <div style={{ width: tableWidth, display: 'flex', justifyContent: 'center', padding: '10px'}}>
            <TdsSpinner size='md'/>
          </div>
        </td>
      </tr>
    )}
    {isErrorLoadingDriverData && (
      <tr className={`${styles.tableRow} ${vehicleRowIndex % 2 === 0 ? styles.evenRow : styles.oddRow}`}>
        <td colSpan={parameterDetails.length + 1}>
          <TdsMessage
            variant='error'
            minimal
            header={t('EttFelHarUppstått_FörsökIgenSenare_')}
          />
        </td>
      </tr>
    )}
    { driverDataLoadedSuccessfully && (!driverData || driverData?.length === 0) && (
      <tr className={`${styles.tableRow} ${vehicleRowIndex % 2 === 0 ? styles.evenRow : styles.oddRow}`}>
        <td colSpan={parameterDetails.length + 1}>
          <TdsMessage  variant='information' minimal header={t('IngenDataAttVisa')} />
        </td>
      </tr>
    )}
    { driverDataLoadedSuccessfully && driverData && driverData?.length > 0 &&
      <>
        {sortedDriverData.map(
          (singleDriverRow: any, rowIndex: number) => (
            <>
              <tr key={`driverRowKey${rowIndex + 1}_${equipmentReference}`}
                id={`driverRow${rowIndex+1}_${equipmentReference}`}
                className={`${styles.tableRow} ${styles.driverRow} ${!isUnknownDriver(singleDriverRow) ? 'clickableElement' : undefined} ${vehicleRowIndex % 2 === 0 ? styles.evenRow : styles.oddRow}`}
                onClick={(e) => { e.preventDefault(); goToDriver(singleDriverRow); }}>
                <td key={`driverRow${rowIndex + 1}firstcolumn`} className={`leftAlignedText ${styles.stickyLeft} ${styles.driverNameCell} ${vehicleRowIndex % 2 === 0 ? styles.evenRow : styles.oddRow} ${styles.driverRowCell}`}>
                  <div className={styles.driverImage} key={`Image_driverRow${rowIndex + 1}_${equipmentReference}`}>
                    <span>{getDriverInitials(singleDriverRow)}</span>
                  </div>
                  
                  <span id={`driverPageTooltip${rowIndex+1}_${equipmentReference}`}>
                    {t(displayAvailableDriverIdentifier(singleDriverRow))}
                  </span>
                </td>
                {parameterDetails.map((singleColumn, columnIndex) => {
                  const metric = singleDriverRow.parameters?.find((element: { name: string; }) => element.name?.toLowerCase() === singleColumn.toLowerCase());
                  const formattedValue = !metric ? '' : displayFormattedValue(metric);
                  return <td
                          key={`driverRow${rowIndex + 1}column${columnIndex + 1}for${equipmentReference}`}
                          className={styles.cellWidth}>
                            {!isNaN(formattedValue) ? formattedValue.toLocaleString() : formattedValue}
                        </td>
                })}
              </tr>
          
              <TdsTooltip
                placement='bottom-start'
                offsetDistance={-4}
                offsetSkidding={16}
                text={t('GåTillFörarensSida')}
                selector={`#driverRow${rowIndex+1}_${equipmentReference}`}
                className={`${isUnknownDriver(singleDriverRow) ? 'hiddenElement' : ''}`}
              />
            </>
          ))}
          <tr className={`${styles.tableRow} ${vehicleRowIndex % 2 === 0 ? styles.evenRow : styles.oddRow}`}>
            <td colSpan={parameterDetails.length + 1} className={`leftAlignedText ${styles.buttonsRowCell}`}>
              <div className={styles.actionButtonsRow}>
                <a
                  className={styles.vehicleTrackingLink}
                  target='_blank'
                  rel='noreferrer'
                  href={`${process.env.REACT_APP_FLEET_POSITION_HOST}/tracking/vehicle-tracking/${equipmentReference}`}>
                    {t('SpåraFordon')} <img src={redirectIcon} alt={t('SpåraFordon')} width={14} />
                </a>
                <span className={styles.exportDriversButton}>
                  <ExportExcelButton
                    buttonText={t('VP_ExportDriverData')}
                    buttonType='secondary'
                    sheetTitle={t('Förarpersonal')}
                    typeOfData='driver'
                    vehicleRow={vehicleData}
                    tableData={sortedDriverData}
                    tableWidgetColumns={tableWidgetSpecs?.properties?.columns}
                    staffDetails={staffDetails}
                    interval={{
                      startDate: periodStart,
                      endDate: periodEnd,
                    }}
                    disabled={isDriverDataFetching ||
                      isDriverDataLoading ||
                      isErrorLoadingDriverData ||
                      (driverDataLoadedSuccessfully && (!driverData || driverData?.length === 0)) ||
                      !tableWidgetSpecs?.properties?.columns
                    }
                  />
                </span>
              </div>
            </td>
          </tr>
      </>
    }
  </>
}

export default DriverDetailsTable;