import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import dayjs from 'dayjs';
import { TdsButton, TdsDivider, TdsIcon, TdsLink, TdsMessage, TdsSpinner, TdsTextField, TdsTooltip } from '@scania/tegel-react';

import ViewsDropdown from '../components/dropdowns/ViewsDropdown';
import GroupsDropdown from '../components/dropdowns/GroupsDropdown';
import FuelTypeDropdown from '../components/dropdowns/FuelTypeDropdown';
import VehicleTypeDropdown from '../components/dropdowns/VehicleTypeDropdown';
import DataTable from '../components/overview/DataTable';
import ExportExcelButton from '../components/export/ExportExcelButton';
import ViewModal from '../components/overview/ViewModal';
import ToastContainer from '../components/ToastContainer';
import DateTimeRangePicker from '../components/common/DateTimeRangePicker';

import { useGetStaffInfoQuery } from '../state/user/query';
import { useGetDashboardSpecificationQuery, useGetUserViewsQuery } from '../state/views/query';
import { useGetCustomerGroupsQuery } from '../state/groups/query';
import { useAppDispatch, useAppSelector } from '../state/store';
import {
  changeOverviewEndDate,
  changeOverviewStartDate,
} from '../state/localSettings/slice';
import {
  getOverviewEndDate,
  getOverviewStartDate,
} from '../state/localSettings/selectors';
import { useGetEquipmentsQuery } from '../state/equipments/query';
import { useGetStaffSettingsQuery } from '../state/settingsApi/query';

import { useDebounce, useGuidedTour } from '../utils/hooks';
import { getDashboardGroupParameters, transformToAcceptedGroupPayload } from '../utils/report/tableUtils';
import { getIdentifierForEqRef } from '../utils/general';

import '../styles/Overview.css';
import '../styles/GeneralStyles.css';
import '../styles/ShepherdGuidedTour.css';

const FILTER_DEBOUNCING_INTERVAL = 100;
const dateTimeFormat = 'YYYY-MM-DDTHH:mm';

const Overview = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { keycloak } = useKeycloak();
  const loggedIn = !!(keycloak?.authenticated && keycloak.token);
  const [searchParams] = useSearchParams();
  const externalEquipmentRef = searchParams.get('externalEquipmentReference');
  
  const searchElementRef = useRef<HTMLTdsTextFieldElement>(null);
  const [searchedTextInput, setSearchedTextInput] = useState<string>('');

  const defaultStartDate = dayjs()
    .subtract(30, 'day')
    .startOf('hour')
    .format(dateTimeFormat);
  const defaultEndDate = dayjs().startOf('hour').format(dateTimeFormat);
  const overviewStartDate = useAppSelector(getOverviewStartDate);
  const overviewEndDate = useAppSelector(getOverviewEndDate);
  const [sortedTableData, setSortedTableData] = useState<TableDataStructure>({ equipments: [], average: [] });

  const [modalSelector, setModalSelector] = useState('');

  const [toastsToDisplay, setToastsToDisplay] = useState<Array<Toast>>([]);
  const [selectedGroup, setSelectedGroup] = useState<string>('groupFilterAll');
  const [selectedDashboardRef, setSelectedDashboardRef] = useState('');
  const [selectedFuelTypes, setSelectedFuelTypes] = useState<string[]>([]);
  const [selectedVehicleTypes, setSelectedVehicleTypes] = useState<string[]>([]);
  const guidedTour = useGuidedTour();
  const [shouldShowGuidedTour, setShouldShowGuidedTour] = useState<boolean>(false);
  const [isTourStarted, setIsTourStarted] = useState<boolean>(false);

  const [staffRetriesLimiter, setStaffRetriesLimiter] = useState<number>(1);

  const debouncedValues = useDebounce(
    JSON.stringify({
      fuelTypes: selectedFuelTypes,
      equipmentTypes: selectedVehicleTypes,
      externalEquipmentGroupReference: selectedGroup,
      externalEquipmentReference: externalEquipmentRef
    }),
    'reference',
    FILTER_DEBOUNCING_INTERVAL
  );
  const {
    data: staffData,
    isLoading: isStaffLoading,
    isSuccess: isStaffLoadingSuccess,
    isError: isStaffLoadingError,
    refetch: refetchStaffData,
  } = useGetStaffInfoQuery(null);

  const { data: staffSettings } = useGetStaffSettingsQuery(isStaffLoadingSuccess ? null : skipToken)
  
  const {
    data: customerGroups,
    isSuccess: groupsLoadedSuccessfully,
    isError: isErrorLoadingGroups,
    refetch: refetchUserGroups,
  } = useGetCustomerGroupsQuery(isStaffLoadingSuccess ? null : skipToken);

  const {
    data: userViews,
    isSuccess: userViewsLoadedSuccessfully,
    isError: isErrorLoadingUserViews,
    // refetch: refetchUserViews,
    isFetching: isUserViewsFetching,
  } = useGetUserViewsQuery(isStaffLoadingSuccess ? null : skipToken);

  const {
    data: selectedDashboardSpecs,
    isSuccess: dashboardSpecsLoadedSuccessfully,
    isError: isErrorLoadingDashboardSpecs,
    isFetching: isDashboardSpecsFetching,
    isLoading: isDashboardSpecsLoading,
  } = useGetDashboardSpecificationQuery(
    selectedDashboardRef && userViewsLoadedSuccessfully && userViews && userViews.length > 0 ? selectedDashboardRef : skipToken
  );

  const tableWidgetMetrics = getDashboardGroupParameters(selectedDashboardSpecs?.widgets[0]?.properties?.columns);

  const {
    data: equipmentsData,
    isLoading: isEquipmentsLoading,
    isSuccess: equipmentsLoadedSuccessfully,
    isError: isErrorLoadingEquipments,
    isFetching: isEquipmentsFetching,
  } = useGetEquipmentsQuery(
    isStaffLoadingSuccess &&
    overviewStartDate &&
    overviewEndDate &&
    selectedDashboardRef.length &&
    dashboardSpecsLoadedSuccessfully &&
    !isDashboardSpecsFetching &&
    !isDashboardSpecsLoading &&
    selectedDashboardSpecs
      ? {
          queryStart: overviewStartDate,
          queryStop: overviewEndDate,
          propulsionConsumptionUnit: staffData
            ? staffData.propulsionConsumptionUnit
            : null,
          gasConsumptionUnit: staffData ? staffData.gasConsumptionUnit : null,
          fuelTypes: debouncedValues.fuelTypes,
          // assuming we'll receive the UTC difference as '-02:45' or '+05:00', the + sign is not mandatory
          utcDiff: staffData && staffData.customer?.utcDiff ? staffData.customer.utcDiff : null,
          parameters: tableWidgetMetrics,
          equipmentTypes: debouncedValues.equipmentTypes,
          externalEquipmentGroupReference: transformToAcceptedGroupPayload(
            debouncedValues.externalEquipmentGroupReference
          ),
          externalEquipmentReference: debouncedValues.externalEquipmentReference
        }
      : skipToken
  );

  const handleSearchForText = useCallback(
    (e: any) => {
      const currentSearchedText = e.detail?.target?.value?.trim() || '';
      setSearchedTextInput(currentSearchedText);
    }, []
  );

  useEffect(() => {
    if (userViewsLoadedSuccessfully && !isUserViewsFetching && userViews && userViews.length) {
      const selectedDashboard = userViews.find((view: View)=> view.isDefault === true);
      if (selectedDashboard && selectedDashboard.dashboardReference) {
        setSelectedDashboardRef(selectedDashboard.dashboardReference);
      }
    }
  }, [userViews, isUserViewsFetching, userViewsLoadedSuccessfully]);

  const isSelectedDashboardNotEditable = selectedDashboardSpecs?.title ? selectedDashboardSpecs.title.isTranslationKeyId : true;

  useEffect(() => {
    if (equipmentsLoadedSuccessfully && !isEquipmentsFetching && !isEquipmentsLoading) {
      setSortedTableData(equipmentsData);
    }
  }, [equipmentsData, equipmentsLoadedSuccessfully, isEquipmentsFetching, isEquipmentsLoading]);

  useEffect(() => {
    if (isStaffLoadingError && staffRetriesLimiter <= 5) {
      setStaffRetriesLimiter(prevState => prevState + 1)
      refetchStaffData();
    }
  }, [isStaffLoadingError, refetchStaffData, staffRetriesLimiter]);

  /* useEffect(() => {
    if (isErrorLoadingUserViews) {
      refetchUserViews();
    }
  }, [isErrorLoadingUserViews, refetchUserViews]); */

  useEffect(() => {
    if (isErrorLoadingGroups) {
      refetchUserGroups();
    }
  }, [isErrorLoadingGroups, refetchUserGroups]);

  useEffect(() => {
    if (
      location.search.length === 0 ||
      !(overviewStartDate && overviewEndDate)
    ) {
      navigate({
        pathname: '/overview',
        search: `?periodStart=${defaultStartDate}&periodEnd=${defaultEndDate}`,
      });
    }
  }, [
    navigate,
    overviewStartDate,
    overviewEndDate,
    location,
    defaultStartDate,
    defaultEndDate,
  ]);

  useEffect(() => {
    const start = searchParams.get('periodStart');
    const end = searchParams.get('periodEnd');
    const bothDatesValid = dayjs(start).isValid() && dayjs(end).isValid();

    if (bothDatesValid) {
      dispatch(changeOverviewStartDate(start));
      dispatch(changeOverviewEndDate(end));
    }
  }, [searchParams, dispatch]);

  useEffect(() => {
    const userHasCompletedMyScaniaTour = staffSettings?.scpNavbarTour?.header && staffSettings?.scpNavbarTour?.sidenav;
    const showGuidedTour = localStorage.getItem('VP.ShowGuidedTour') !== 'false' && userHasCompletedMyScaniaTour;

    setShouldShowGuidedTour(showGuidedTour);
  }, [staffSettings, setShouldShowGuidedTour]);

  useEffect(() => {
    const isInMobileView = window.matchMedia('(max-width: 768px)').matches;
    const enableVPTour = (e: any) => {
      if(isInMobileView && e.type === 'navbarHeaderTourCompleted') {
        const backdrop = document.querySelector('.sss-navbar__overlay--expanded') as HTMLElement

        backdrop?.click()
      }
      setShouldShowGuidedTour(true);
    };

    const mobileEvents = ['navbarHeaderTourCanceled', 'navbarHeaderTourCancelIcon', 'navbarHeaderTourCompleted'];
    const desktopEvents = ['navbarSidenavTourCanceled', 'navbarSidenavTourCancelIcon', 'navbarSidenavTourCompleted'];

    const addEventListeners = (events: typeof mobileEvents | typeof desktopEvents) => {
      events.forEach(event => document.addEventListener(event, enableVPTour));
    };

    const removeEventListeners = (events: typeof mobileEvents | typeof desktopEvents) => {
      events.forEach(event => document.removeEventListener(event, enableVPTour));
    };

    if (isInMobileView) {
      addEventListeners(mobileEvents);
      return () => removeEventListeners(mobileEvents);
    } else {
      addEventListeners(desktopEvents);
      return () => removeEventListeners(desktopEvents);
    }
  }, [setShouldShowGuidedTour]);

  useEffect(() => {
    if (staffData && localStorage.getItem('VP.ShowGuidedTour') !== 'false' && shouldShowGuidedTour && !isTourStarted && groupsLoadedSuccessfully && userViewsLoadedSuccessfully && equipmentsLoadedSuccessfully) {
      guidedTour.start();
      setIsTourStarted(true);
    }
  }, [equipmentsLoadedSuccessfully, groupsLoadedSuccessfully, guidedTour, isStaffLoadingSuccess, isTourStarted, shouldShowGuidedTour, staffData, userViewsLoadedSuccessfully])

  useEffect(() => {
      if (staffData) {
      const searchTextField = searchElementRef.current;
      if (!searchTextField) return;

      searchTextField.addEventListener('tdsInput', handleSearchForText);

      return () => {
        searchTextField.removeEventListener('tdsInput', handleSearchForText);
      };
    }
  }, [handleSearchForText, staffData]);

  useEffect(() => {
    if (equipmentsLoadedSuccessfully && 
      !isEquipmentsFetching &&
      !isEquipmentsLoading &&
      staffData &&
      !isStaffLoading &&
      externalEquipmentRef) {
        setSearchedTextInput(getIdentifierForEqRef(externalEquipmentRef, equipmentsData, staffData.vehicleIdentifier));
    }
  }, [equipmentsData, equipmentsLoadedSuccessfully, isEquipmentsFetching, isEquipmentsLoading, staffData, isStaffLoading, externalEquipmentRef]);

  const searchNewInterval = ({
    startDate,
    endDate,
  }: {
    [key: string]: string;
  }) => {
    dispatch(changeOverviewStartDate(startDate));
    dispatch(changeOverviewEndDate(endDate));

    navigate(`/overview?periodStart=${startDate}&periodEnd=${endDate}${externalEquipmentRef ? `&externalEquipmentReference=${externalEquipmentRef}` : ''}`);
  };

  const pushNotificationToToast = (newNotification: Toast) => {
    setToastsToDisplay((toasts) => [...toasts, newNotification]);

    setTimeout(() => {
      removeToast(newNotification.id);
    }, 5000);
  };

  const removeToast = (toastId: number | string) => {
    setToastsToDisplay((toasts) =>
      toasts.filter((toast) => toast.id !== toastId)
    );
  };

  const clearPreselectedEquipment = () => {
    setSearchedTextInput('');
    if (externalEquipmentRef) {
      searchParams.delete('externalEquipmentReference');
      navigate({
        pathname: '/overview',
        search: `?periodStart=${overviewStartDate}&periodEnd=${overviewEndDate}`,
      });
    }
  }

  const clearFilteredEqOnGroupSelection = (groupId: string = '') => {
    clearPreselectedEquipment();
    setSelectedGroup(groupId);
  }

  return (
    <div style={{position: 'relative', top: 0, left: 0}}>
      <div className='mainContainer'>
        <div className='pageHeader'>
          <div className='centerAlignedFlex'>
            <h5>{t('Översikt')}</h5>

            {loggedIn && staffData && groupsLoadedSuccessfully && (
              <>
                <p className='headerItemDivider'></p>
                <div className='groupsDropdownContainer'>
                  <GroupsDropdown
                    groups={customerGroups}
                    groupsLoadedSuccessfully={groupsLoadedSuccessfully}
                    onSelection={(groupId: any) => clearFilteredEqOnGroupSelection(groupId)}
                    isDisabled={!userViewsLoadedSuccessfully}
                  />
                </div>
              </>
            )}
          </div>

          {loggedIn && staffData && (
            <div className='overviewDatePickerContainer'>
              <DateTimeRangePicker
                periodStart={overviewStartDate}
                periodEnd={overviewEndDate}
                staffStartOfWeek={staffData.customer?.startOfWeek}
                staffLanguage={staffData.language}
                searchNewInterval={searchNewInterval}
              />
              <div>
                <TdsTextField
                  type='text'
                  size='md'
                  ref={searchElementRef}
                  placeholder={t('Sök')}
                  value={searchedTextInput}
                  modeVariant='secondary'
                  disabled={!(userViewsLoadedSuccessfully &&
                    !isUserViewsFetching &&
                    equipmentsLoadedSuccessfully &&
                    !isEquipmentsFetching) ||
                    isErrorLoadingEquipments ||
                    isErrorLoadingDashboardSpecs ||
                    !equipmentsData?.equipments?.length ||
                    (externalEquipmentRef !== null && externalEquipmentRef?.length > 0)
                  }
                  className={externalEquipmentRef !== null && externalEquipmentRef?.length > 0 ? 'readOnlyInput' : ''}
                >
                  { (searchedTextInput?.length > 0 && equipmentsData?.equipments?.length) || externalEquipmentRef !== null ?
                    <TdsIcon className='clickableElement' slot='suffix' name='cross' size='20px' onClick={clearPreselectedEquipment} />
                    :                        
                    <TdsIcon slot='suffix' name='search' size='20px' />
                  }
                </TdsTextField>
              </div>
            </div>
          )}
        </div>
        <div className='infoMessageContainer'>
          <TdsMessage
            variant='error'
            header=''
            modeVariant='secondary'>
              {t('VP_RecreateTemplatesAsViews').replace('{1}', ` ${dayjs(new Date(2024, 9, 31)).format('D')} ${t('December')}`)}
              <TdsLink
                style={{marginLeft: '10px'}}
                onClick={() => setModalSelector('createViewButton')}>
                  <span>{t('VP_CreateView')}</span>
              </TdsLink>
          </TdsMessage> 
        </div>

      {loggedIn && (
        <div className='pageContentContainer'>
          {(isStaffLoading || isUserViewsFetching) && (
            <div className='spinnerContainer'>
              <TdsSpinner size='md' />
            </div>
          )}

          {(isStaffLoadingError && staffRetriesLimiter > 5) && (
            <TdsMessage
              variant='error'
              header={t('EttFelHarUppstått_FörsökIgenSenare_')}
            />
          )}

          {staffData && (
            <>
              {userViewsLoadedSuccessfully && !isUserViewsFetching && (
                <div className='dropdownsContainer'>
                  <div id='shepherd-views-container'>
                    <ViewsDropdown
                      views={userViews}
                      updateSelectedView={setSelectedDashboardRef}
                    />
                    <>
                      <span id='editViewButtonContainer'>
                        <TdsButton
                          type='button'
                          variant='secondary'
                          size='sm'
                          disabled={isSelectedDashboardNotEditable || !selectedDashboardSpecs?.title}
                          id='editViewButton'
                          onClick={() => setModalSelector('editViewButton')}>
                          <TdsIcon slot='icon' size='16px' name='edit'></TdsIcon>
                        </TdsButton>
                      </span>
                      { isSelectedDashboardNotEditable &&
                        <TdsTooltip
                          placement='bottom'
                          selector='#editViewButtonContainer'
                          text={t('VP_SkapaNyaVyer')}
                          className='buttonTooltip'
                        />
                      }
                      { !isSelectedDashboardNotEditable &&
                        <TdsTooltip
                          placement='bottom'
                          selector='#editViewButtonContainer'
                          text={t('VP_EditView')}
                          className='buttonTooltip'
                        />
                      }
                      <TdsButton
                        type='button'
                        variant='primary'
                        size='sm'
                        className='createViewButton'
                        id='createViewButton'
                        text={t('VP_CreateView')}
                        onClick={() => setModalSelector('createViewButton')} />
                      <ViewModal
                        header={modalSelector === 'createViewButton' ? t('VP_CreateNewView'): t('VP_EditView') }
                        selector={modalSelector}
                        size='lg'
                        propulsionConsumptionUnit={
                          staffData.propulsionConsumptionUnit
                        }
                        gasConsumptionUnit={staffData.gasConsumptionUnit}
                        tableWidgetDetails={selectedDashboardSpecs}
                        pushNotification={pushNotificationToToast}
                        updateSelectedView={setSelectedDashboardRef}
                        setSelector={setModalSelector}
                      />
                    </>
                  </div>
                  <div className='dividerContainer'>
                    <TdsDivider orientation='vertical' />
                  </div>
                  <div id='shepherd-filters-container'>
                  <FuelTypeDropdown
                    selectedOptions={selectedFuelTypes}
                    onSelection={setSelectedFuelTypes}
                    externalEquipmentReference = {externalEquipmentRef}
                    />
                  <VehicleTypeDropdown
                    selectedOptions={selectedVehicleTypes}
                    onSelection={setSelectedVehicleTypes}
                    externalEquipmentReference = {externalEquipmentRef}
                    />
                  </div>
                  {equipmentsLoadedSuccessfully && (
                    <div className='exportExcelButton'>
                      <ExportExcelButton
                        buttonText={t('ExporteraFordonsdata')} // need to add a key for 'Export equipments' when ready
                        buttonType='secondary'
                        sheetTitle={t('Rapport')}
                        typeOfData='overview'
                        equipmentRef={externalEquipmentRef}
                        tableData={sortedTableData}
                        tableWidgetColumns={selectedDashboardSpecs?.widgets ? selectedDashboardSpecs?.widgets[0]?.properties?.columns : []}
                        staffDetails={staffData}
                        interval={{
                          startDate: overviewStartDate,
                          endDate: overviewEndDate,
                        }}
                        disabled={
                          isEquipmentsFetching ||
                          (sortedTableData &&
                            sortedTableData.equipments?.length === 0)
                        }
                      />
                    </div>
                  )}
                </div>
              )}
              { userViewsLoadedSuccessfully &&
                !isUserViewsFetching &&
                (isEquipmentsLoading ||
                  isEquipmentsFetching ||
                  isDashboardSpecsFetching ||
                  isDashboardSpecsLoading) && (
                  <div className='spinnerContainer'>
                    <TdsSpinner size='md' />
                  </div>
                )}
              {(isErrorLoadingEquipments || isErrorLoadingUserViews || isErrorLoadingDashboardSpecs) && (
                <TdsMessage
                  variant='error'
                  header={t('EttFelHarUppstått_FörsökIgenSenare_')}
                />
              )}
              { userViewsLoadedSuccessfully &&
                !isUserViewsFetching &&
                equipmentsLoadedSuccessfully &&
                !isEquipmentsLoading &&
                !isEquipmentsFetching &&
                dashboardSpecsLoadedSuccessfully &&
                !isDashboardSpecsFetching &&
                !isDashboardSpecsLoading && (
                  <div className='tableContainer'
                      style={{ minHeight: equipmentsData?.equipments?.length <= 10 ?
                        `${equipmentsData?.equipments?.length * 65 + 82}'px'` : '820px' }}>
                    <DataTable
                      staffDetails={staffData}
                      tableData={equipmentsData}
                      tableSubcolumns={tableWidgetMetrics}
                      tableWidgetSpecs={selectedDashboardSpecs?.widgets ? selectedDashboardSpecs?.widgets[0] : null}
                      periodStart={overviewStartDate}
                      periodEnd={overviewEndDate}
                      searchedText={searchedTextInput}
                      externalEquipmentRef={externalEquipmentRef}
                      updateSortedData={setSortedTableData}
                    />
                  </div>
                )}
            </>
          )}
        </div>
      )}
      {toastsToDisplay.length > 0 && (
        <ToastContainer toasts={toastsToDisplay} removeToast={removeToast} />
      )}
    </div>
    </div>
  );
};

export default Overview;
