import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useKeycloak } from '@react-keycloak/web';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import dayjs from 'dayjs';

import { useGetCustomerGroupsQuery } from '../state/groups/query';
import { useGetStaffInfoQuery } from '../state/user/query';
import { useIsFeatureEnabled } from '../utils/hooks';
import { useGetUserViewsQuery } from '../state/views/query';

import GroupsDropdown from '../components/dropdowns/GroupsDropdown';
import ViewsDropdown from '../components/dropdowns/ViewsDropdown';

import '../styles/GeneralStyles.css';
import styles from '../styles/Dashboard.module.css';

import { TdsButton, TdsLink, TdsMessage, TdsSpinner } from '@scania/tegel-react';
import DateTimeRangePicker from '../components/common/DateTimeRangePicker';

import FiltersButton from '../components/filters/FiltersButton';
import FiltersContent from '../components/filters/FiltersContent';

const dateTimeFormat = 'YYYY-MM-DDTHH:mm';

const Dashboard = () => {
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();
  const loggedIn = !!(keycloak?.authenticated && keycloak.token);

  const isDashboardPageAccessible = useIsFeatureEnabled('VPHEV');

  const defaultStartDate = dayjs()
    .subtract(30, 'day')
    .startOf('hour')
    .format(dateTimeFormat);
  const defaultEndDate = dayjs().startOf('hour').format(dateTimeFormat);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedGroup, setSelectedGroup] = useState(undefined);
  const [dashboardStartDate, setDashboardStartDate] =
    useState(defaultStartDate);
  const [dashboardEndDate, setDashboardEndDate] = useState(defaultEndDate);
  const [dashboards, setDashboards] = useState([]);

  const [selectedFuelTypes, setSelectedFuelTypes] = useState<string[]>([]);
  const [selectedVehicleTypes, setSelectedVehicleTypes] = useState<string[]>([]);

  const {
    data: staffData,
    isLoading: isStaffLoading,
    isSuccess: isStaffLoadingSuccess,
    isError: isStaffLoadingError,
    refetch: refetchStaffData,
  } = useGetStaffInfoQuery(null);

  const {
    data: customerGroups,
    isSuccess: groupsLoadedSuccessfully,
    isError: isErrorLoadingGroups,
    isFetching: isCustomerGroupsFetching,
  } = useGetCustomerGroupsQuery(isStaffLoadingSuccess ? null : skipToken);

  useEffect(() => {
    if (isStaffLoadingError) {
      refetchStaffData();
    }
  }, [isStaffLoadingError, refetchStaffData]);

  const searchNewInterval = ({
    startDate,
    endDate,
  }: {
    [key: string]: string;
  }) => {
    setDashboardStartDate(dayjs(startDate).format(dateTimeFormat));
    setDashboardEndDate(dayjs(endDate).format(dateTimeFormat));
  };

  const {
    data: fetchedDashboards,
    isSuccess: isDashboardsLoadedSuccessfully,
    isError: isErrorLoadingDashboards,
    isLoading: isDashboardsLoading,
    isFetching: isDashboardsFetching,
  } = useGetUserViewsQuery(isStaffLoadingSuccess ? null : skipToken);

  useEffect(() => {
    if (isDashboardsLoadedSuccessfully && fetchedDashboards) {
      setDashboards(fetchedDashboards);
    }
  }, [isDashboardsLoadedSuccessfully, fetchedDashboards]);

  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded((prev) => !prev);
  };

  const handleClose = () => {
    setIsExpanded(false);
  };

  const selectedCount = (selectedFuelTypes.length > 0 ? 1 : 0) + (selectedVehicleTypes.length > 0 ? 1 : 0);

  return (
    <div className='mainContainer'>
      <div className='pageHeader'>
        <div className='header2'>{t('Anslagstavla')}</div>
        {loggedIn && isDashboardPageAccessible && (
          <div className={styles.inlineFlexContainer}>
            {(isDashboardsFetching || isDashboardsLoading) && (
              <div className={styles.spinnerContainer}>
                <TdsSpinner size='md' />
              </div>
            )}
            {isDashboardsLoadedSuccessfully && dashboards.length > 0 && (
              <ViewsDropdown
                views={dashboards}
                updateSelectedView={() => {}}
                size='md'
                labelPosition='inside'
                label={t('Anslagstavla')}
                modeVariant='secondary'
              />
            )}
            <TdsButton
              type='button'
              variant='primary'
              size='md'
              text={t('Create Dashboard')}
              disabled={true}
            />
          </div>
        )}
      </div>
      {loggedIn && !isDashboardPageAccessible && (
        <div className='pageContentContainer'>{t('DuHarInteBehörighet_')}</div>
      )}
      {loggedIn && isDashboardPageAccessible && (
        <>
          <div className='pageContentContainer'>
            {(isErrorLoadingGroups || isErrorLoadingDashboards) && (
              <TdsMessage
                variant='error'
                header={t('EttFelHarUppstått_FörsökIgenSenare_')}
              />
            )}

            {(isStaffLoading || isCustomerGroupsFetching) && (
              <div className='spinnerContainer'>
                <TdsSpinner size='md' />
              </div>
            )}

            {staffData && groupsLoadedSuccessfully && (
              <div className={styles.flexContainer}>
                <div className={styles.groupsDropdownContainer}>
                  <GroupsDropdown
                    groups={customerGroups}
                    groupsLoadedSuccessfully={groupsLoadedSuccessfully}
                    onSelection={setSelectedGroup}
                    isDisabled={false}
                    variantMode='primary'
                    label={t('Grupper')}
                    labelPosition='inside'
                  />
                </div>
                <div className={styles.overviewDatePickerContainer}>
                  <DateTimeRangePicker
                    periodStart={dashboardStartDate}
                    periodEnd={dashboardEndDate}
                    staffStartOfWeek={staffData.customer?.startOfWeek}
                    staffLanguage={staffData.language}
                    customClassName='dashboardDatepicker'
                    searchNewInterval={searchNewInterval}
                  />
                </div>
                <div className={styles.filtersButtonContainer}>
                  <FiltersButton
                    isExpanded={isExpanded}
                    onToggle={handleToggle}
                  />
                  {selectedCount > 0 && (
                    <>
                      <span className={styles.filterBadge}>
                        <span>{selectedCount}</span>
                      </span>
                      <TdsLink>
                        <span onClick={() => { setSelectedFuelTypes([]); setSelectedVehicleTypes([]); }} className={styles.clearFiltersButton}>
                          {t('Clear filters')}
                        </span>
                      </TdsLink>
                    </>
                  )}

                </div>
                <div className='fullWidth'>
                  <FiltersContent
                    isExpanded={isExpanded} 
                    onClose={handleClose}
                    selectedFuelTypes={selectedFuelTypes} 
                    setSelectedFuelTypes={setSelectedFuelTypes} 
                    selectedVehicleTypes={selectedVehicleTypes} 
                    setSelectedVehicleTypes={setSelectedVehicleTypes} 
                    externalEquipmentReference={null} 
                  />
                </div>
              </div>
            )}
          </div>

          {staffData && (
            <div className='pageContentContainer'>
              {groupsLoadedSuccessfully && (
                <div className='onlyTableContainer'>widgets for dashboard</div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Dashboard;
