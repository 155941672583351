// @ts-nocheck
import { Fragment, useEffect, useState } from 'react';
import { TdsMessage } from '@scania/tegel-react';
import { useTranslation } from 'react-i18next';

import DataTableHeader from './DataTableHeader';
import DriverDetailsTable from './DriverDetailsTable';
import {
  displayFormattedValue,
  filterTableRowsOnSearch,
  getAvailableIdentifier,
  getMatchingPropForVehicleName,
  sortRows,
  createDeepCopy,
} from '../../utils/report/tableUtils';
import chevronUpIcon from '../../assets/chevronUp.svg'
import chevronDownIcon from '../../assets/chevronDown.svg'

import styles from '../../styles/DataTable.module.css';
import '../../styles/GeneralStyles.css';

type dataTableProps = {
  staffDetails: StaffDetails;
  tableData: TableDataStructure;
  tableWidgetSpecs: WidgetSpecification;
  tableSubcolumns: string[];
  periodStart: string;
  periodEnd: string;
  searchedText: string;
  externalEquipmentRef: string | undefined | null;
  updateSortedData: Function;
};

const DataTable = ({
  staffDetails,
  tableData,
  tableWidgetSpecs,
  tableSubcolumns,
  periodStart,
  periodEnd,
  searchedText,
  externalEquipmentRef,
  updateSortedData,
}: dataTableProps) => {
  const { t } = useTranslation();
  const [tableDataContent, setTableDataContent] =
    useState<TableDataStructure>(tableData);
  const [expandedVehicleRows, setExpandedVehicleRows] = useState(externalEquipmentRef ? [externalEquipmentRef] : []);
  const [sortDetails, setSortDetails] = useState({ sortField: '', sortOrder: ''});

  const updateExpandedVehicleRows = (clickedVehicleReference: string) => {
    let tempExpandedRows = [...expandedVehicleRows];
    if (!tempExpandedRows.includes(clickedVehicleReference)) {
      tempExpandedRows.push(clickedVehicleReference);
    }
    else {
      // the row was expanded so we need to remove it from the expanded rows list upon click
      tempExpandedRows = tempExpandedRows.filter((vehicleRowRef) => vehicleRowRef !== clickedVehicleReference);
    }

    setExpandedVehicleRows(tempExpandedRows);
  }
  
  const sortTableRows = (sortField: string, sortOrder: string, tableDataToSort = tableDataContent) => {
    if (sortField) {
      setSortDetails({ sortField: sortField, sortOrder: sortOrder });
      
      const sortedEquipments = sortRows(
        sortField,
        sortOrder,
        tableDataToSort.equipments
      );

      const tableDataDeepCopy = createDeepCopy(tableDataToSort);
      tableDataDeepCopy.equipments = sortedEquipments;

      setTableDataContent(tableDataDeepCopy);

      updateSortedData(tableDataDeepCopy);
    }
  };

  useEffect(() => setTableDataContent(tableData), [tableData]);

  useEffect(() => {
   const filteredEquipments = filterTableRowsOnSearch(searchedText, tableData, staffDetails.vehicleIdentifier, sortDetails);
   setTableDataContent(filteredEquipments);
   updateSortedData(filteredEquipments);
  }, [searchedText, tableData, staffDetails.vehicleIdentifier, sortDetails, updateSortedData]);

  return (
    <div>
      {tableWidgetSpecs &&
        !tableDataContent.equipments?.length && (
          <div className='messagePadding'><TdsMessage variant='information' minimal header={t('IngenDataAttVisa')}/></div>
        )}
      {tableWidgetSpecs &&
        tableDataContent.equipments?.length > 0 && (
          <table className='tds-table--responsive fullWidth'>
            <DataTableHeader
              vehicleIdentifier={getMatchingPropForVehicleName(
                staffDetails.vehicleIdentifier
              )}
              propulsionConsumptionUnit={staffDetails.propulsionConsumptionUnit}
              tableWidgetSpecs={tableWidgetSpecs}
              parameterDetails={tableDataContent.equipments[0].parameters}
              sortTableContent={sortTableRows}
            />
            <tbody>
              { /* average row will be displayed ONLY when NOT coming from other apps */ }
              {!externalEquipmentRef &&
                <tr className={`${styles.tableRow}`}>
                  <td
                    key='keySummary1_1'
                    className={`leftAlignedText ${styles.summaryRowCell} ${styles.paddedContent} ${styles.stickyLeft}`}>
                    {t('TF_FleetAverage')}
                  </td>
                  {tableSubcolumns.map((subcolumn: string, index: number) => {
                    const paramToDisplay = tableDataContent.average?.find(metric => metric.name.toLowerCase() === subcolumn.toLowerCase());
                    
                    return <td
                        key={`keySummary${index + 2}`}
                        className={`rightAlignedText ${styles.summaryRowCell}`}>
                        {displayFormattedValue(paramToDisplay)}
                      </td>
                  })}
                </tr>
              }
              {tableDataContent.equipments?.map(
                (singleVehicle: TableWidgetEquipment, rowIndex: number) => {
                  const isExpandedRow = expandedVehicleRows.includes(singleVehicle.externalEquipmentReference);
                  const currentVehicleIdentifier = getAvailableIdentifier(
                    getMatchingPropForVehicleName(
                      staffDetails.vehicleIdentifier
                    ),
                    singleVehicle.equipmentInfo
                  );

                  return (
                    <Fragment key={`vehicleRow${rowIndex + 1}Container`}>
                      <tr
                        key={`vehicleRow${rowIndex + 1}`}
                        onClick={(e) => { e.preventDefault(); updateExpandedVehicleRows(singleVehicle.externalEquipmentReference)}}
                        // the clickable class should be added only when token permissions allow user to see driver details
                        className={`clickableElement ${styles.tableRow} ${
                          isExpandedRow ? styles.expandedRow :
                          (rowIndex % 2 === 1 || externalEquipmentRef?.length) ? styles.oddRow : styles.evenRow
                        } `}>
                        <td
                          key={`keyVehicleRow${rowIndex + 1}Group1Col1`}
                          className={`leftAlignedText ${styles.stickyLeft} ${
                            isExpandedRow ? styles.expandedRow : (rowIndex % 2 === 1 || externalEquipmentRef?.length) ? styles.oddRow : styles.evenRow
                          }`}>
                          <div style={{display: 'flex', gap: '16px'}} key={`keyVehicleRowImage${rowIndex + 1}Group1Col1`}>
                            <img
                              id={rowIndex === 0 ? 'shepherd-driver-details-button' : ''}
                              src={isExpandedRow ? chevronUpIcon : chevronDownIcon}
                              alt={isExpandedRow ? t('Stäng') : t('Öppen')}
                              width={14}
                            />
                            {currentVehicleIdentifier}
                          </div>
                        </td>
                        {tableSubcolumns.map((subcolumn: string, index: number) => {
                          const paramToDisplay = singleVehicle.parameters.find(metric => metric.name.toLowerCase() === subcolumn.toLowerCase());
                          const formattedValue = displayFormattedValue(paramToDisplay);

                          return <td
                            key={`keyVehicleRow${rowIndex + 1}Group${index + 2}`}
                            className={`rightAlignedText ${styles.cellWidth}`}>
                            <div style={{ minWidth: 'max-content' }}>
                              {!isNaN(formattedValue) ? formattedValue.toLocaleString() : formattedValue}
                            </div>
                          </td>
                        })}
                      </tr>
                      { // the table below returns driver rows only when permissions are met on the BE side to view driver data
                        isExpandedRow &&
                          <DriverDetailsTable
                            staffDetails={staffDetails}
                            periodStart={periodStart}
                            periodEnd={periodEnd}
                            sortDetails={sortDetails}
                            equipmentReference={singleVehicle.externalEquipmentReference}
                            tableWidgetSpecs={tableWidgetSpecs}
                            parameterDetails={tableSubcolumns}
                            vehicleRowIndex={externalEquipmentRef?.length ? 1 : rowIndex}
                            vehicleData={singleVehicle}
                          />
                      }
                    </Fragment>
                  )
                }
              )}
            </tbody>
          </table>
        )}
    </div>
  );
};

export default DataTable;