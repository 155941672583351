import React from 'react';
import { TdsIcon } from '@scania/tegel-react';
import FuelTypeDropdown from '../dropdowns/FuelTypeDropdown';
import VehicleTypeDropdown from '../dropdowns/VehicleTypeDropdown';
import styles from '../../styles/Filters.module.css';

interface FiltersContentProps {
  isExpanded: boolean;
  onClose: () => void;
  selectedFuelTypes: string[];
  setSelectedFuelTypes: React.Dispatch<React.SetStateAction<string[]>>;
  selectedVehicleTypes: string[];
  setSelectedVehicleTypes: React.Dispatch<React.SetStateAction<string[]>>;
  externalEquipmentReference: string | null;
}

const FiltersContent = ({
  isExpanded,
  onClose,
  selectedFuelTypes,
  setSelectedFuelTypes,
  selectedVehicleTypes,
  setSelectedVehicleTypes,
  externalEquipmentReference
}: FiltersContentProps) => {
  if (!isExpanded) return null;

  return (
    <div className={styles.filtersContainer}>
      <h6 className={`${styles.filtersHeader} detail-04`}>Filters</h6>
      <TdsIcon
        className={`${styles.filtersCloseIcon} clickableElement`}
        name="cross"
        size="20px"
        onClick={onClose}
      />
      <div className={styles.filtersDropdowns}>
        <FuelTypeDropdown
          selectedOptions={selectedFuelTypes}
          onSelection={setSelectedFuelTypes}
          externalEquipmentReference={externalEquipmentReference}
          labelPosition="inside"
          modeVariant="secondary"
          minWidth="256px"
        />
        <VehicleTypeDropdown
          selectedOptions={selectedVehicleTypes}
          onSelection={setSelectedVehicleTypes}
          externalEquipmentReference={externalEquipmentReference}
          labelPosition="inside"
          modeVariant="secondary"
          minWidth="256px"
        />
      </div>
    </div>
  );
};

export default FiltersContent;
