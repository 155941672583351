import ExcelJS from 'exceljs';
import FileSaver from 'file-saver';
import dayjs from 'dayjs';

import {
  adjustOdometerToProfileSetting,
  getAvailableIdentifier,
  getMatchingPropForVehicleName,
} from './tableUtils';
import i18n from '../localization';
import { localDate } from '../general';

type Props = {
  sheetTitle: string;
  tableData: FuelReportItem[];
  staffDetails: StaffDetails;
  interval: {
    startDate: string;
    endDate: string;
  };
};

const dateTimeFormat = 'YYYY-MM-DD HH:mm';

export const createFuelReportExcelWorkbook = async ({
  sheetTitle,
  tableData,
  staffDetails,
  interval,
}: Props) => {
  const workbook = new ExcelJS.Workbook();
  let date = new Date();
  workbook.creator = 'Scania - Vehicle Performance';
  workbook.created = date;
  workbook.views = [
    {
      x: 0,
      y: 0,
      width: 10000,
      height: 20000,
      firstSheet: 0,
      activeTab: 0,
      visibility: 'visible',
    },
  ];

  const reportSheet = workbook.addWorksheet(sheetTitle, {
    properties: { defaultColWidth: 20 },
  });

  const customerNameRow = reportSheet.getRow(1);
  customerNameRow.height = 60;
  customerNameRow.alignment = { vertical: 'middle' };
  customerNameRow.getCell(1).value = staffDetails.customer?.name;

  const fuelReportPeriodRow = reportSheet.getRow(2);
  fuelReportPeriodRow.height = 60;
  fuelReportPeriodRow.alignment = { vertical: 'middle' };
  fuelReportPeriodRow.getCell(1).value = `${i18n.t(
    'Bränslerapport'
  )}: ${dayjs(interval.startDate).format(dateTimeFormat)} - ${dayjs(
    interval.endDate
  ).format(dateTimeFormat)}`;

  const headerRow = reportSheet.getRow(4);

  const createTableHeaderRow = (): Array<Record<string, string>> => [
      {
        param: 'equipment',
        translationKey: 'Utrustning'
      },
      {
        param: 'time',
        translationKey: 'Tid_Tankning_'
      },
      {
        param: 'odometer',
        translationKey: 'Vägmätare'
      },
      {
        param: 'event',
        translationKey: 'Händelse'
      },
      {
        param: 'change',
        translationKey: 'Förändringar'
      },
      {
        param: 'increase',
        translationKey: 'Ökning'
      },
      {
        param: 'driver',
        translationKey: 'Förare'
      },
      {
        param: 'address',
        translationKey: 'Adress'
      },
    ];

  const createTableBodyRows = () => {
    const tableBodyRows: Array<string | number>[] = [];
    tableData.forEach((entry: FuelReportItem) => {
      const { alias, chassisNumber, registrationNumber } = entry.equipment;
      const currentEntry = {
        equipment: getAvailableIdentifier(getMatchingPropForVehicleName(staffDetails.vehicleIdentifier), { alias, chassisNumber, registrationNumber }),
        time: localDate(entry.eventTime),
        odometer: adjustOdometerToProfileSetting(entry.odometerInMeters, staffDetails.propulsionConsumptionUnit),
        event: entry.refuelType,
        change: `${entry.oldLevel} % -> ${entry.newLevel} %`,
        increase: entry.increaseInPercentage,
        driver: `${entry.driver?.name || i18n.t('IngetFörar_Id')}`,
        address: entry.address
      }

      tableBodyRows.push(Object.values(currentEntry))
    })

    return tableBodyRows;
  }

  const generateTableHeader = () => {
    const tableHeaderCells = createTableHeaderRow();
    let currentColumnNumber = 1;

    for (let i = 0; i < tableHeaderCells.length; i++) {
      let cell = reportSheet.getCell(headerRow.number, currentColumnNumber);
        cell.value = tableHeaderCells[i].param === 'increase' ? `${i18n.t(tableHeaderCells[i].translationKey)} (%)` : tableHeaderCells[i].param === 'odometer' ? `${i18n.t(tableHeaderCells[i].translationKey)} (${staffDetails.propulsionConsumptionUnit.includes('miles') ? i18n.t('Miles'): i18n.t('Km')})` : i18n.t(tableHeaderCells[i].translationKey);
        currentColumnNumber++;
    }

    headerRow.alignment = { vertical: 'middle' };
    headerRow.font = { bold: true, underline: 'single' };
  };

  // -----Table generation-----

  generateTableHeader();

  const tableBodyRows = createTableBodyRows();

  const bodyRows = reportSheet.addRows(tableBodyRows);
  bodyRows.forEach(row => {
    row.alignment = { horizontal: 'right' };
    row.getCell(1).alignment = { horizontal: 'left' };
  });

  // -----Table download-----

  try {
    const buffer = await workbook.xlsx.writeBuffer();
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8;';
    let EXCEL_EXTENSION = '.xlsx';
    const blob = new Blob([buffer], { type: fileType });

    FileSaver.saveAs(
      blob,
      `${i18n.t('Bränslerapport')} ${dayjs(interval.startDate).format(
        dateTimeFormat
      )} - ${dayjs(interval.endDate).format(dateTimeFormat)}` + EXCEL_EXTENSION
    );
  } catch (e) {
    console.error(e);
  }
};
